<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-right">
                    <h1> Prospectos </h1>
                </div>
            </div>
        </div>

        <DataGridV3 :configuracion="dataGridConfig" :data="prospectos" :seleccionar="seleccionar">
            <div class="row">
                <div class="col-sm-auto">
                    <button class="btn btn-primary" @click="obtener_prospectos"><i class='fa fa-sync-alt'></i>
                        Recargar</button>
                </div>
            </div>
        </DataGridV3>
    </div>
</template>

<script>
import apiCliente from '@/apps/clientes/api/clientes'
import DataGridV3 from '@/components/DataGridV3'

export default {
    components: { DataGridV3 },
    data() {
        return {
            open_modal_prospecto: false,
            dataGridConfig: {
                name: 'prospectos',
                cols: {
                    nombre_completo: 'Nombre completo',
                    email: 'Correo electronico',
                    telefono: 'Telefono',
                    tipo_persona: 'Razón social',
                    curp: 'CURP',
                    estatus: 'Estatus',
                    eventos: 'Evento'
                },
                paginator: {
                    pagina_actual: 1
                    , total_registros: 1
                    , registros_por_pagina: 20
                },
                mutators: {
                    nombre_completo: function (val, row) {
                        return `${row.nombre} ${row.paterno} ${row.materno}`
                    },
                    curp: function (val, row) {
                        return val ? val.substr(0, 6) + '**********' : val;
                    },
                    estatus: function (val, row) {
                        return "<span class='" + (row.status ? 'badge badge-success' : 'badge badge-danger') + "'>" + (row.status ? 'Activo' : 'Inactivo') + "</span>";
                    },
                    eventos(val, row, vue) {
                        const [eventosInicioSesion, eventosVideosTerminados] = [
                            row.eventos.filter(evento => evento.evento === "inicio_sesion"),
                            row.eventos.filter(evento => evento.evento === "videos_terminados")
                        ];

                        return eventosInicioSesion.length > 0 && eventosVideosTerminados.length > 0
                            ? "Ya cuenta con el evento, inicio de sesión y videos terminados"
                            : eventosInicioSesion.length > 0
                                ? "Ya cuenta con el evento, inicio de sesión"
                                : eventosVideosTerminados.length > 0
                                    ? "Ya cuenta con el evento, videos terminados"
                                    : "Aún no cuenta con ningun evento";
                    }
                },
                show_search: true,
                search: ['nombre', 'paterno', 'telefono'],
                selector: false,
                options: {
                    page: 1
                    , order_col: 'id'
                    , order_dir: 'desc'
                    , limit: 20
                    , filters: []
                }

            },
            prospectos: [],
            prospecto: {},
            seleccionar: false
        }
    },
    mounted() {
        this.obtener_prospectos()
    },
    methods: {
        async obtener_prospectos() {
            this.prospectos = (await apiCliente.listar_prospectos()).data
        }
    }
}

</script>